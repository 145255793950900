// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-author-js": () => import("./../../../src/pages/author.js" /* webpackChunkName: "component---src-pages-author-js" */),
  "component---src-pages-autor-js": () => import("./../../../src/pages/autor.js" /* webpackChunkName: "component---src-pages-autor-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-en-js": () => import("./../../../src/pages/en.js" /* webpackChunkName: "component---src-pages-en-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-projects-journey-js": () => import("./../../../src/pages/projects/journey.js" /* webpackChunkName: "component---src-pages-projects-journey-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-projects-life-js": () => import("./../../../src/pages/projects/life.js" /* webpackChunkName: "component---src-pages-projects-life-js" */),
  "component---src-pages-projects-logo-js": () => import("./../../../src/pages/projects/logo.js" /* webpackChunkName: "component---src-pages-projects-logo-js" */),
  "component---src-pages-projects-martyna-wojciechowska-js": () => import("./../../../src/pages/projects/martyna-wojciechowska.js" /* webpackChunkName: "component---src-pages-projects-martyna-wojciechowska-js" */),
  "component---src-pages-projects-team-value-partners-js": () => import("./../../../src/pages/projects/team-value-partners.js" /* webpackChunkName: "component---src-pages-projects-team-value-partners-js" */),
  "component---src-pages-projects-ufg-js": () => import("./../../../src/pages/projects/ufg.js" /* webpackChunkName: "component---src-pages-projects-ufg-js" */),
  "component---src-pages-projekty-js": () => import("./../../../src/pages/projekty.js" /* webpackChunkName: "component---src-pages-projekty-js" */),
  "component---src-pages-projekty-life-js": () => import("./../../../src/pages/projekty/life.js" /* webpackChunkName: "component---src-pages-projekty-life-js" */),
  "component---src-pages-projekty-logo-js": () => import("./../../../src/pages/projekty/logo.js" /* webpackChunkName: "component---src-pages-projekty-logo-js" */),
  "component---src-pages-projekty-martyna-wojciechowska-js": () => import("./../../../src/pages/projekty/martyna-wojciechowska.js" /* webpackChunkName: "component---src-pages-projekty-martyna-wojciechowska-js" */),
  "component---src-pages-projekty-podroz-js": () => import("./../../../src/pages/projekty/podroz.js" /* webpackChunkName: "component---src-pages-projekty-podroz-js" */),
  "component---src-pages-projekty-team-value-partners-js": () => import("./../../../src/pages/projekty/team-value-partners.js" /* webpackChunkName: "component---src-pages-projekty-team-value-partners-js" */),
  "component---src-pages-projekty-ubezpieczeniowy-fundusz-gwarancyjny-js": () => import("./../../../src/pages/projekty/ubezpieczeniowy-fundusz-gwarancyjny.js" /* webpackChunkName: "component---src-pages-projekty-ubezpieczeniowy-fundusz-gwarancyjny-js" */)
}

