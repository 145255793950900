module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"powszuk-com","short_name":"powszuk","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"static/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"58e726c06076d41d1df0b0d5f3761af6"},
    },{
      plugin: require('../node_modules/gatsby-v2-plugin-page-transitions/gatsby-browser.js'),
      options: {"plugins":[],"transitionTime":350},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
